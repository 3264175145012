/* src/styles/ui-styling-redesign.css */

/* Variables */
:root {
    --primary-red: #ff3b3b;
    --primary-orange: #ff8a00;
    --accent-white: #f5f5f5;
    --shadow-dark: rgba(0, 0, 0, 0.25);
    --button-height: 50px;
    --button-radius: 15px;
    --button-width: 80px; /* Variable for button width */
  }
  
  /* General Styles */
  body {
    font-family: 'Arial', sans-serif;
    background-color: #f2f2f2;
  }
  
  .app-container {
    max-width: 1000px;
    margin: 0 auto;
    padding: 20px;
  }
  
  header {
    text-align: center;
    background-color: var(--primary-red);
    color: white;
    padding: 20px;
    border-radius: 0 0 25px 25px;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  /* Button Styles */
  button {
    height: var(--button-height);
    border-radius: var(--button-radius);
    border: none;
    color: white;
    font-weight: bold;
    font-size: 16px;
    cursor: pointer;
    padding: 10px;
    transition: all 0.2s ease-in-out;
    box-shadow: 0 6px 0 var(--shadow-dark), 0 10px 15px rgba(0, 0, 0, 0.3);
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin: 5px;
  }
  
  button:hover {
    transform: translateY(-4px);
    box-shadow: 0 8px 0 var(--shadow-dark), 0 14px 20px rgba(0, 0, 0, 0.4);
  }
  
  button:active {
    transform: translateY(2px);
    box-shadow: 0 2px 0 var(--shadow-dark), 0 8px 10px rgba(0, 0, 0, 0.2);
  }
  
  .edit-button {
    background-color: var(--primary-red);
    width: calc(2 * var(--button-width)); /* Double the width */
  }
  
  .save-button {
    background-color: var(--primary-orange);
    width: calc(2 * var(--button-width)); /* Double the width */
  }
  
  .load-button {
    background-color: var(--primary-red);
    width: calc(2 * var(--button-width)); /* Double the width */
  }
  
  .reset-button {
    background-color: var(--primary-orange);
    width: calc(2 * var(--button-width)); /* Double the width */
  }
  
  .generate-button {
    width: 100%;
    height: 60px;
    font-size: 20px;
    background-color: var(--primary-red);
  }
  
  .print-button {
    width: 100%;
    height: 50px;
    background-color: var(--primary-orange);
  }
  
  .button-group {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  
  /* Grid Styles */
  .grid {
    display: grid;
    grid-template-columns: repeat(6, 1fr); /* Set to 6 columns */
    gap: 15px;
    margin: 20px 0;
    padding: 20px;
    background-color: var(--accent-white);
    border-radius: 20px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  textarea {
    padding: 10px;
    border-radius: 10px;
    border: none;
    box-shadow: inset 0 4px 8px rgba(0, 0, 0, 0.1);
    resize: none;
    font-size: 14px;
    font-weight: bold;
    color: #333;
    background-color: #ffe6e6;
    text-align: center;
  }
  
  textarea.selected {
    border: 2px solid blue;
  }
  
  .selected-field-display {
    width: 100%;
    height: 50px;
    padding: 10px;
    border-radius: 10px;
    border: none;
    background-color: #ffe6e6;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    color: #333;
    margin-bottom: 20px;
  }
  
  /* QR Code Styles */
  .qr-code-section {
    text-align: center;
    margin-top: 20px;
  }
  
  .qr-code-container {
    display: inline-block;
    padding: 20px;
    border-radius: 20px;
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    margin-bottom: 20px;
  }
  
  #qr-code {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  /* Hidden File Input */
  .file-input {
    display: none;
  }
  